import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginContainer from "./pages/Login/LoginContainer";
import Home from "./pages/Home";
import { getCookie } from "./utils/utils";
import PasswordReset from "./pages/PasswordReset/PasswordResetForm/PasswordResetFormContainer";

export default props => {
    const isLogged = getCookie('isLogged');
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/login" component={LoginContainer} />
                <Route exact path="/passwordreset" component={PasswordReset} />
                <Route path="/home" component={Home} />
                <Route
                    path="/"
                    render={() => (
                        isLogged ?
                            <Redirect to="/home" /> :
                            <Redirect to="/login" />
                    )}
                />
                <Route path="/404" component={() => (<div>404</div>)} />
                <Redirect from='*' to='/404' />
            </Switch>
        </HashRouter>
    );
}