import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField/index';
import { Autocomplete } from '@material-ui/lab/index';
import { get, handleResponse, swal500 } from '../../../utils/network';

class RoleAutocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionLabels: [],
            options: [],
            value: "",
            loading: false
        }
    }

    componentDidMount() {
        this.props.value?.role && this.setState({
            value: this.props.value?.role,
            options: [this.props.value],
            optionLabels: [this.props.value?.role]
        });
    }

    fillAutocomplete = newValue => {
        const regex = new RegExp(' ');
        const nameParam = newValue.replace(regex, '%20');
        get(`${this.props.path}?role=like:${nameParam}&page=0&size=30`)
            .then(res => handleResponse(res, this.props))
            .then(parsed =>
                this.setState({
                    options: parsed.message.data,
                    optionLabels: parsed.message.data.map(o => o.role),
                    loading: false
                })
            )
            .catch(err => {
                swal500(err);
                this.setState({ loading: false });
            });
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue, loading: true, options: [], optionLabels: [] });
        this.fillAutocomplete(newValue);
    };

    handleSelect = (event, newValue) => {
        const selected = this.state.options.filter(o => o.role === newValue)[0];
        if(selected || !newValue) this.props.handleChange(selected || "");
    };

    render() {
        return (
            <Autocomplete
                id="controled-autocomplete"
                options={this.state.optionLabels}
                autoHighlight
                value={this.state.value}
                onInputChange={this.handleChange}
                onChange={(event, newValue) => this.handleSelect(event, newValue)}
                loading={this.state.loading}
                loadingText="Cargando..."
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={this.props.title}
                        margin="normal"
                        error={this.props.error !== undefined}
                        helperText={this.props.error}
                    />
                )}
            />
        )
    }
}

export default RoleAutocomplete