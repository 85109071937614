export const CUIL_REGEX = /^(20|23|24|27|30|33|34)[0-9]{5,}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default (values, isNewUser) => {
  let errors = {};

  if (!values.name) errors.name = "Complete el Nombre";

  if (!values.metabaseId) {
    errors.metabaseId = "Complete el ID de tablero";
  } else if (values.metabaseId < 0) {
    errors.metabaseId = "El ID de tablero debe ser mayor a 0";
  }

  return errors;
};
