import React from "react";

const oauthOptions = [
    {
        label: "Iniciar sesión con Conectar Igualdad",
        backgroundColor: "#1AC2C2",
        icon: <img src="logo-oauth.svg" width={40} height={30} alt="conectar-igualdad" />,
        endpoint: "auth/login/oauth2/educar"
    },
];

export default oauthOptions;