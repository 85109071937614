import React, { useCallback, useEffect, useState, memo } from "react";
import { useHistory } from "react-router-dom";
import { TextField, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { get, handleResponse, swal500 } from "../../../utils/network";
import useDebounce from "../useDebounce";
import { textFieldStyle, autocompleteStyle, compactStyle } from "./styles";

const EMPTY_ARRAY = [];

const getName = value => `${value.firstName} ${value.lastName}`;

const filterOptions = createFilterOptions({
  stringify: value => getName(value),
});

function FullNameAutocomplete({
  value,
  label,
  error,
  handleChange,
  disabled = false,
  showInput = false,
  dependsOn,
  hide,
  filterUsers = EMPTY_ARRAY,
  compact,
  helperText,
}) {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [previousDependsOnValue, setPreviousDependsOnValue] = useState(dependsOn);
  const basePathWithParams = `users?page=0`;

  const fillAutocomplete = useCallback(
    newValue => {
      if (!basePathWithParams) return;
      const regex = new RegExp(" ");
      const nameParam = newValue.replace(regex, "%20");
      setLoading(true);
      const endpoint = nameParam
        ? `${basePathWithParams}&fullName=${nameParam}`
        : basePathWithParams;
      get(endpoint)
        .then(res => handleResponse(res, { history }))
        .then(parsed => {
          const items = parsed.message.data.filter(
            i => i._id && !filterUsers.map(i => i._id).includes(i._id)
          );
          setOptions(items);
        })
        .catch(swal500)
        .finally(() => setLoading(false));
    },
    [basePathWithParams, history, filterUsers]
  );

  useEffect(() => {
    if (value && value._id) {
      setCurrentValue(getName(value));
      setOptions([]);
    } else if (!disabled) {
      fillAutocomplete("");
    }
  }, [disabled, value, fillAutocomplete]);

  useEffect(() => {
    if (dependsOn !== undefined && dependsOn !== previousDependsOnValue) {
      setPreviousDependsOnValue(dependsOn);
      setCurrentValue("");
      setOptions([]);
      handleChange("");
      fillAutocomplete("");
    }
  }, [dependsOn, disabled, fillAutocomplete, previousDependsOnValue, handleChange]);

  const handleInputChange = (event, newValue, reason) => {
    if (reason !== "reset" || newValue) setCurrentValue(newValue);
    if (reason !== "reset") {
      setOptions([]);
      setLoading(true);
      fillAutocomplete(newValue);
    }
  };

  const onInputChange = useDebounce(handleInputChange, 400);

  const handleSelect = (event, newValue) => {
    let selected;
    selected = options.filter(o => o._id === newValue?._id)[0];
    if (selected || !newValue) handleChange(selected || "");
  };

  return hide ? null : showInput ? (
    <TextField
      value={getName(value)}
      label={label}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      style={textFieldStyle}
    />
  ) : (
    <Autocomplete
      options={options}
      autoHighlight
      disabled={disabled}
      filterOptions={filterOptions}
      value={currentValue || null}
      onInputChange={onInputChange}
      onChange={(event, newValue) => handleSelect(event, newValue)}
      loading={loading}
      getOptionSelected={(option, value) =>
        getName(option).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      }
      getOptionLabel={option => (option._id ? getName(option) : option)}
      loadingText="Cargando..."
      noOptionsText="No hay opciones"
      openText="Abrir"
      clearText="Borrar"
      closeText="Cerrar"
      style={autocompleteStyle}
      renderOption={option => <Typography>{getName(option)}</Typography>}
      renderInput={params => (
        <form>
          <input
            type="password"
            style={{
              width: 0,
              height: 0,
              visibility: "hidden",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          />
          <TextField
            {...params}
            style={compact && compactStyle}
            label={label}
            margin="normal"
            error={error !== undefined}
            helperText={helperText || error}
          />
        </form>
      )}
    />
  );
}

export default memo(FullNameAutocomplete);
