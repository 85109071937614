import React, { useCallback } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from "@material-ui/icons";
import styles from "./styles";
import { FIELDS } from "./DashboardsFormContainer";
import { FullNameAutocompleteAdd, FormLayout, UsersSelectorTable } from "../../common";

const DashboardsFormComponent = props => {
  const {
    classes,
    errors,
    handleChange,
    handleBlur,
    handleClose,
    handleResult,
    handleDelete,
    submitting,
    loading,
    values,
    editMode,
    setEditMode,
    isNew,
    smallScreen,
  } = props;

  const renderActions = () => (
    <div className={classes.topActionsContainer}>
      {!editMode && (
        <IconButton onClick={() => setEditMode(true)}>
          <CreateIcon />
        </IconButton>
      )}
      {!isNew && (
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );

  const handleSwitch = e =>
    handleChange({ target: { name: "jurisdictionFilter", value: e.target.checked } });

  const handleDeleteUser = i => {
    const newUsers = values.allowedUsers.filter((u, index) => i !== index);
    handleChange({
      target: {
        name: "allowedUsers",
        value: newUsers,
      },
    });
  };

  const handleAddUser = useCallback(
    i =>
      handleChange({
        target: {
          name: "allowedUsers",
          value: [...values.allowedUsers, i],
        },
      }),
    [values.allowedUsers, handleChange]
  );

  return (
    <FormLayout
      handleClose={handleClose}
      renderActions={renderActions}
      loading={loading}
      editMode={editMode}
      submitting={submitting}
      handleSubmit={handleResult}
      isNew={isNew}
      dataKey="Tablero"
    >
      {(!!values.name || editMode) && (
        <TextField
          name="name"
          error={errors.name !== undefined}
          helperText={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          label={FIELDS["name"]}
          className={classes.textField}
          InputProps={{
            readOnly: !editMode,
            disabled: !editMode,
            disableUnderline: !editMode,
          }}
        />
      )}
      {(!!values.metabaseId || editMode) && (
        <TextField
          name="metabaseId"
          error={errors.metabaseId !== undefined}
          helperText={errors.metabaseId}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.metabaseId}
          label={FIELDS["metabaseId"]}
          className={classes.textField}
          type="number"
          InputProps={{
            readOnly: !editMode,
            disabled: !editMode,
            disableUnderline: !editMode,
          }}
        />
      )}
      <div style={{ display: "flex", flexDirection: "row", marginTop: 13 }}>
        <FormControlLabel
          checked={values.jurisdictionFilter}
          control={<Checkbox disabled={!editMode} onChange={handleSwitch} color="primary" />}
          label={
            <Typography
              style={{
                fontSize: 16,
                color: editMode ? "black" : "rgba(0, 0, 0, 0.38)",
                marginRight: 20,
              }}
            >
              {FIELDS["jurisdictionFilter"] + (smallScreen ? "" : " en este tablero")}
            </Typography>
          }
          style={{ paddingLeft: 0 }}
        />
      </div>
      {editMode && (
        <FullNameAutocompleteAdd
          onAdd={handleAddUser}
          title={FIELDS["allowedUsers"]}
          filterUsers={values.allowedUsers}
          smallScreen={smallScreen}
          error={errors.allowedUsers}
        />
      )}
      <UsersSelectorTable
        users={values.allowedUsers}
        editMode={editMode}
        deleteUser={handleDeleteUser}
      />
    </FormLayout>
  );
};

export default withStyles(styles)(DashboardsFormComponent);
