import React, { useCallback, useState, memo } from "react";
import { Button, IconButton } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import FullNameAutocomplete from "./FullNameAutocomplete";

function FullNameAutocompleteAdd({ title, error, smallScreen, onAdd, filterUsers }) {
  const [value, setValue] = useState({});
  const [resetValue, setResetValue] = useState(false);
  const handleAdd = () => {
    if (value._id) {
      onAdd(value);
      setResetValue(r => !r);
      setValue({});
    }
  };
  const handleChange = useCallback(value => setValue(value), [setValue]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <FullNameAutocomplete
        value={value || null}
        label={title}
        handleChange={handleChange}
        error={error}
        dependsOn={resetValue}
        filterUsers={filterUsers}
        compact
      />
      {smallScreen ? (
        <IconButton style={{ padding: 0, marginLeft: 5 }} onClick={handleAdd}>
          <AddCircle color="primary" />
        </IconButton>
      ) : (
        <Button
          onClick={handleAdd}
          color="primary"
          variant="contained"
          style={{ padding: "2px 7px", marginLeft: 10 }}
        >
          Agregar
        </Button>
      )}
    </div>
  );
}

export default memo(FullNameAutocompleteAdd);
