import React from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Routes from './routes';

let theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#238da5'
        },
        primary: {
            main: '#2DB0B0',
            contrastText: '#FFFFFF'
        },
        state: {
            success: "#1B9F20",
            failure: "#FF0000"
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['"Verdana"', 'sans-serif'].join(','),
        useNextVariants: true,
    }

});

theme = responsiveFontSizes(theme);

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <Routes />
        </MuiThemeProvider>
    );
}

export default App;
