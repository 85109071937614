import {FormLayout} from "../../common";
import React from "react";
import {IconButton, TextField, withStyles} from "@material-ui/core";
import {Create as CreateIcon, Delete as DeleteIcon} from "@material-ui/icons";
import styles from "./styles";
import PermissionsSelector from "../../common/PermissionsSelector/PermissionsSelector";

const RolesFormComponent = (props) => {
    const {
        handleClose,
        loading,
        editMode,
        submitting,
        handleSubmit,
        isNewRole,
        setEditMode,
        handleDelete,
        errors,
        handleChange,
        handleBlur,
        classes,
        values,
        permissions,
        loadingPermissions,
        handleChangePermission,
        handleChangeCategory
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewRole &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleSubmit}
            isNew={isNewRole}
            dataKey="Rol"
        >
            <TextField
                name="role"
                error={errors.role !== undefined}
                helperText={errors.role}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                label="Nombre del rol"
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode,
                    autoComplete: 'off'
                }}
            />
            <PermissionsSelector
                permissions={permissions}
                editMode={editMode}
                loading={loadingPermissions}
                handleChangePermission={handleChangePermission}
                handleChangeCategory={handleChangeCategory}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(RolesFormComponent);