export const sleep = (timeMS, onTimer) => new Promise((resolve, reject) => setTimeout(() => onTimer(resolve, reject), timeMS));

export const deleteCookie = name => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export const getCookie = name => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

export const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const isLogged = () => getCookie('isLogged');
export const logout = () => deleteCookie('isLogged');