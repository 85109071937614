import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  CircularProgress,
  CssBaseline,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Hidden,
  ListSubheader,
} from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import { Group, Security, Phonelink, Person, Dashboard } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/utils";
import { get, handleResponse } from "../../utils/network";

const DRAWER_WIDTH = 270;

export const drawerOptions = [
  {
    label: "Usuarios",
    value: 0,
    path: "/home/users",
    icon: Group,
    permissionNeeded: "viewUsers",
    section: 0,
  },
  {
    label: "Roles",
    value: 1,
    path: "/home/roles",
    icon: Security,
    permissionNeeded: "viewRoles",
    section: 0,
  },
  {
    label: "Tableros",
    value: 2,
    path: "/home/dashboards",
    icon: Dashboard,
    permissionNeeded: "manageDashboards",
    section: 0,
  },
  {
    label: "Perfil",
    value: 3,
    path: "/home/profile",
    icon: Person,
    permissionNeeded: "none",
    section: 0,
  },
  {
    label: "Sesiones",
    value: 4,
    path: "/home/sessions",
    icon: Phonelink,
    permissionNeeded: "manageOthersSessions",
    section: 0,
  },
];

const drawerSections = [
  {
    value: 0,
    label: "Mi cuenta",
  },
  {
    value: 1,
    label: "Tableros",
    async: true,
  },
];

const styles = theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
  container: {
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#EDEDED",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
    },
  },
  drawerPaper: {
    zIndex: 1000,
    width: DRAWER_WIDTH,
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  itemText: {
    fontWeight: 500,
    fontSize: 14,
  },
  listSubheader: {
    lineHeight: 0,
    paddingBottom: 15,
    fontWeight: 600,
    color: "#9e9c9c",
    fontSize: 12,
  },
});

class DrawerLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOptions,
      value:
        drawerOptions.find(option => this.props.history.location.pathname.includes(option.path))
          ?.value || 0,
      smallScreen: false,
      routeOptions: [],
      loadingDrawerOptions: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.setState({ routeOptions: JSON.parse(getCookie("routeOptions")) });
    this.getDashboards();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize() {
    this.setState({ smallScreen: window.innerHeight <= 735 });
  }

  handleChange = index => {
    this.setState({ value: index }, () => this.props.handleDrawerToggleMobile(false));
  };

  focusItem = valor => {
    if (this.state.value === valor)
      return {
        justifyContent: "center",
        backgroundColor: this.props.theme.palette.primary.main,
        color: "white",
        marginTop: 10,
      };
    else return { justifyContent: "center", color: "grey", marginTop: 10 };
  };

  getDashboards = () =>
    get("dashboards/mine")
      .then(res => handleResponse(res, this.props))
      .then(parsed => {
        this.setState(prevState => {
          const newDrawerOptions = [
            ...prevState.drawerOptions,
            ...parsed.message.data.map((d, index) => ({
              ...d,
              icon: Dashboard,
              path: `/home/view-dashboards/${d._id}`,
              _id: d._id,
              label: d.name,
              section: 1,
              value: drawerOptions.length + index,
              permissionNeeded: "none",
            })),
          ];
          return {
            drawerOptions: newDrawerOptions,
            loadingDrawerOptions: false,
            value:
              newDrawerOptions.find(option =>
                this.props.history.location.pathname.includes(option.path)
              )?.value || 0,
          };
        });
      });

  render() {
    const { classes, mobileOpen, handleDrawerToggle, drawerOpen, handleDrawerToggleMobile } =
      this.props;
    const { smallScreen, value, routeOptions } = this.state;
    const drawer = (
      <>
        <div
          style={{
            padding: smallScreen ? 10 : 20,
            marginTop: 20,
            textAlign: "center",
          }}
        >
          {/* <img src="logo.png" width={195} alt="Logo" /> */}
        </div>
        <List>
          {drawerSections.map((section, index) => {
            const sectionOptions = this.state.drawerOptions
              .filter(
                op =>
                  routeOptions.some(r_op => r_op.value === op.value) ||
                  op.path.includes("/home/view-dashboards/")
              )
              .filter(op => op.section === section.value);
            return sectionOptions.length ? (
              <div key={section.value} style={{ display: "flex", flexDirection: "column" }}>
                <ListSubheader
                  className={classes.listSubheader}
                  style={{
                    borderTop: index !== 0 ? "1px solid #ececec" : "unset",
                    marginTop: index !== 0 ? 15 : 0,
                    paddingTop: index !== 0 ? 24 : 0,
                  }}
                >
                  {section.label}
                </ListSubheader>
                {!section.async || !this.state.loadingDrawerOptions ? (
                  sectionOptions.map(option => (
                    <ListItem
                      key={option.value}
                      button
                      style={this.focusItem(option.value)}
                      onClick={() => this.handleChange(option.value)}
                      component={Link}
                      to={option.path}
                    >
                      <ListItemIcon>
                        {React.createElement(option.icon, {
                          style: {
                            color: value === option.value ? "white" : "grey",
                            marginLeft: 10,
                          },
                        })}
                      </ListItemIcon>
                      <ListItemText
                        primary={option.label}
                        classes={{ primary: classes.itemText }}
                      />
                    </ListItem>
                  ))
                ) : (
                  <CircularProgress style={{ alignSelf: "center", marginTop: 100 }} />
                )}
              </div>
            ) : null;
          })}
        </List>
      </>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={() => handleDrawerToggleMobile()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css" >
          <Drawer
            className={classes.drawer}
            style={!drawerOpen ? { display: "none" } : {}}
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={drawerOpen}
            onClose={() => handleDrawerToggle()}
            anchor="left"
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(DrawerLeft));
