import React from "react";
import { Button, IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from "@material-ui/icons";
import RandExp from "randexp";
import styles from "./styles";
import { FIELDS } from "./UserFormContainer";
import { FormLayout, RoleAutocomplete, PermissionsSelector, CustomPassword } from "../../common";
import { getCookie } from "../../../utils/utils";

const UserFormComponent = props => {
    const {
        classes,
        errors,
        handleChange,
        handleChangeRol,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        loadingPermissions,
        values,
        editMode,
        setEditMode,
        isNewUser,
        permissions,
        handleChangePermission,
        handleChangeCategory,
    } = props;

    const isCurrentUser = getCookie("userId") === values?._id;
    const showLocalUserField = isNewUser || (editMode && values?.signUpOrigin === "local_user" );
    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewUser && (
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            )}
        </div>
    );

    const generatePassword = () => {
        const PASSWORD_GEN_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$/;
        let newPassword;
        do {
            //because sometimes the generator fails (1 out of 10 times aprox)
            newPassword = new RandExp(PASSWORD_GEN_REGEX).gen();
        } while (!PASSWORD_GEN_REGEX.test(newPassword));
        handleChange({
            target: {
                name: "password",
                value: newPassword,
            },
        });
    };

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewUser}
            dataKey="Usuario"
        >
            {(!!values.firstName || showLocalUserField) && (
                <TextField
                    name="firstName"
                    error={errors.firstName !== undefined}
                    helperText={errors.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    label={FIELDS["firstName"]}
                    className={classes.textField}
                    InputProps={{
                        readOnly: !showLocalUserField,
                        disabled: !showLocalUserField,
                        disableUnderline: !showLocalUserField,
                    }}
                />
            )}
            {(!!values.lastName || showLocalUserField) && (
                <TextField
                    name="lastName"
                    error={errors.lastName !== undefined}
                    helperText={errors.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    label={FIELDS["lastName"]}
                    className={classes.textField}
                    InputProps={{
                        readOnly: !showLocalUserField,
                        disabled: !showLocalUserField,
                        disableUnderline: !showLocalUserField,
                    }}
                />
            )}
            <TextField
                name="email"
                error={errors.email !== undefined}
                helperText={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label={FIELDS["email"]}
                className={classes.textField}
                disabled={!editMode}
                InputProps={{
                    readOnly: !showLocalUserField,
                    disabled: !showLocalUserField,
                    disableUnderline: !showLocalUserField,
                }}
            />
            <TextField
                name="cuil"
                error={errors.cuil !== undefined}
                helperText={errors.cuil}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cuil}
                label={FIELDS["cuil"]}
                className={classes.textField}
                InputProps={{
                    readOnly: !showLocalUserField,
                    disabled: !showLocalUserField,
                    disableUnderline: !showLocalUserField,
                }}
            />
            {editMode && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <CustomPassword
                        name="password"
                        error={errors.password}
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        label={isNewUser ? FIELDS["password"] : "Cambiar contraseña"}
                        className={classes.textField}
                        hideStartAdornment
                        InputProps={{
                            autoComplete: "new-password",
                        }}
                    />
                    <Button
                        onClick={generatePassword}
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 10, minWidth: "fit-content" }}
                    >
                        Generar contraseña
                    </Button>
                </div>
            )}
            {(editMode || values.role._id) &&
                (editMode && !isCurrentUser ? (
                    <RoleAutocomplete
                        path="roles"
                        value={values.role}
                        title="Rol"
                        handleChange={handleChangeRol}
                        error={errors.role}
                    />
                ) : (
                    values.role && (
                        <TextField
                            name="role"
                            value={values.role?.role}
                            label="Rol"
                            className={classes.textField}
                            InputProps={{
                                readOnly: !isNewUser,
                                disabled: !isNewUser,
                                disableUnderline: !isNewUser,
                            }}
                        />
                    )
                ))}
            {(!isCurrentUser || !editMode) && (
                <PermissionsSelector
                    permissions={permissions}
                    editMode={editMode}
                    loading={loadingPermissions}
                    handleChangePermission={handleChangePermission}
                    handleChangeCategory={handleChangeCategory}
                />
            )}
            {(!!values.jurisdiction || editMode) && (
                <TextField
                    name="jurisdiction"
                    error={errors.jurisdiction !== undefined}
                    helperText={errors.jurisdiction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jurisdiction}
                    label={FIELDS["jurisdiction"]}
                    className={classes.textField}
                    InputProps={{
                        readOnly: !editMode,
                        disabled: !editMode,
                        disableUnderline: !editMode,
                    }}
                />
            )}
        </FormLayout>
    );
};

export default withStyles(styles)(UserFormComponent);
