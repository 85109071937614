import React, { useState } from 'react';
import {
    CircularProgress,
    Fab,
    Hidden,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    withStyles,
    useTheme,
    useMediaQuery,
    Tooltip
} from "@material-ui/core";
import { Add, Create, Delete, Visibility } from '@material-ui/icons';
import styles from './styles';
import { Link } from 'react-router-dom';
import Loading from "../Loading";
import { SuccessToast } from '..';

const ROWS_PER_PAGE = 10;

const TableComponent = ({
    classes,
    data,
    dataKey,
    path,
    newMessage,
    columns,
    handleOpenForm,
    handleDelete,
    handleChangePage,
    successMessage,
    onCloseToast
}) => {
    const theme = useTheme();
    const [rowIdOver, setRowIdOver] = useState('');
    const emptyRows = ROWS_PER_PAGE - Math.min(ROWS_PER_PAGE, data[dataKey].length - data.page * ROWS_PER_PAGE);
    const handleOver = rowId => setRowIdOver(rowId);
    const handleLeave = () => setRowIdOver('');
    const smallScreen = useMediaQuery(theme.breakpoints.down(1020));

    const renderTableHeader = () => (
        columns.map(column => {
            const Cell = () => (
                <TableCell
                    style={{ fontWeight: 600, minWidth: column.minWidth }}
                    classes={{ root: classes.tableHead }}
                >
                    {column.label}
                </TableCell>
            );

            if (column.hidden) {
                return <Hidden key={column.id} {...column.hidden}>
                    <Cell />
                </Hidden>
            } else {
                return <Cell key={column.id} />
            }
        })
    );

    const renderTableRow = row => (
        columns.map(column => {
            const cellProps = {
                onClick: () => handleOpenForm(row._id),
                key: column.id,
                classes: { root: classes.tableCell },
            };
            if (column.hidden) {
                return <Hidden key={column.id} {...column.hidden}>
                    <TableCell {...cellProps}>
                        {column.field  && row[column.id] ? row[column.id][column.field] || "-" : row[column.id] || "-"}
                    </TableCell>
                </Hidden>
            } else {
                return <TableCell {...cellProps}>
                    {column.field && row[column.id] ? row[column.id][column.field] || "-" : row[column.id] || "-"}
                </TableCell>
            }
        })
    );

    const renderActions = row => (
        <div className={classes.iconsContainer}>
            <Tooltip title="Ver información">
                <Link to={path + row._id}>
                    <IconButton>
                        <Visibility />
                    </IconButton>
                </Link>
            </Tooltip>
            <Tooltip title="Editar">
                <Link to={{
                    pathname: path + row._id,
                    editMode: true
                }}>
                    <IconButton>
                        <Create />
                    </IconButton>
                </Link>
            </Tooltip>
            <Tooltip title="Eliminar">
                <IconButton onClick={() => handleDelete(row._id)}>
                    <Delete className={classes.icon} />
                </IconButton>
            </Tooltip>
        </div>
    );

    return (
        <div>
            <Paper className={classes.root}>
                {
                    data.loading ? (
                        <Loading />
                    ) : (
                            <div>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {renderTableHeader()}
                                                <TableCell
                                                    key={'create'}
                                                    style={{ minWidth: smallScreen ? 120 : 230 }}
                                                    className={classes.buttonContainerUpdating}
                                                >
                                                    {
                                                        data.updating &&
                                                            <CircularProgress size={24} style={{ marginRight: 20 }}/>
                                                    }
                                                    <Fab
                                                        size="small"
                                                        color="primary"
                                                        aria-label="add"
                                                        variant={smallScreen ? "round" : "extended"}
                                                        classes={{ root: classes.fabIcon }}
                                                        onClick={() => handleOpenForm()}
                                                    >
                                                        <Add style={{ color: 'white' }} />
                                                        {
                                                            !smallScreen &&
                                                            <p style={{ color: 'white', margin: 0, paddingLeft: 5 }}>
                                                                {newMessage}
                                                            </p>
                                                        }
                                                    </Fab>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data[dataKey]
                                                    .slice(data.page * ROWS_PER_PAGE, data.page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                                    .map(row => {
                                                        const showActions = rowIdOver === row._id || smallScreen;
                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={row._id}
                                                                onMouseLeave={() => handleLeave()}
                                                                onMouseOver={() => handleOver(row._id)}
                                                            >
                                                                {renderTableRow(row)}
                                                                <TableCell
                                                                    style={{ paddingTop: 2, paddingBottom: 2 }}
                                                                    classes={{ root: classes.tableCell }}
                                                                >
                                                                    {
                                                                        showActions
                                                                            ? renderActions(row)
                                                                            : <div style={{ height: '100%', width: '100%' }} />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                            }
                                            {
                                                emptyRows > 0 &&
                                                <TableRow style={{ height: 52 * emptyRows }}>
                                                    <TableCell colSpan={6} classes={{ root: classes.tableCell }} />
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[ROWS_PER_PAGE]}
                                    component="div"
                                    count={data[`${dataKey}Count`]}
                                    rowsPerPage={ROWS_PER_PAGE}
                                    page={data.page}
                                    labelDisplayedRows={({ from, to, count }) => from + " al " + to + " de " + count}
                                    onChangePage={handleChangePage}
                                    classes={{ root: classes.pagination }}
                                />
                            </div>
                        )
                }
            </Paper>
            <SuccessToast
                open={successMessage !== ''}
                onClose={onCloseToast('successMessage')}
                text={successMessage}
            />
        </div>
    )
};

export default withStyles(styles)(TableComponent);
