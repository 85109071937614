export default (theme) => ({
  root: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%'
    }
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 14px 0 24px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topActionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    marginRight: 15,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: '10px 20px 0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  profileCard: {
    width: 350,
    alignItems: 'center',
    margin: '20px 20px 20px 0px',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
    },
  },
  profileCardLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  profileCardActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileCardButton: {
    margin: 10,
    alignSelf: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    padding: '0px 24px 10px 24px',
  },
  textField: {
    marginTop: 13,
    marginBottom: 8,
  },
  inputFile: {
    display: 'none',
  },
  imageLabel: {
    fontSize: 12,
    fontWeight: 200,
    paddingBottom: 10,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 30,
    paddingBottom: 10,
  },
});
