import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as NativeTableCell,
  styled,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

const TableCellHeader = styled(({ editMode, ...other }) => <NativeTableCell {...other} />)({
  padding: 10,
  fontWeight: 600,
  backgroundColor: "white",
  color: ({ editMode }) => (editMode ? "grey" : "rgba(0, 0, 0, 0.38)"),
});

const TableCell = styled(({ editMode, ...other }) => <NativeTableCell {...other} />)({
  padding: 10,
  border: "none",
  color: ({ editMode }) => (editMode ? "black" : "rgba(0, 0, 0, 0.38)"),
});

const UsersSelectorTable = ({ users, deleteUser, editMode }) => {
  return users.length ? (
    <TableContainer component={Paper} style={{ margin: "20px 0px 8px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader editMode={editMode} align="left">
              Nombre
            </TableCellHeader>
            <TableCellHeader editMode={editMode} align="left">
              Apellido
            </TableCellHeader>
            <TableCellHeader editMode={editMode}>
              <div style={{ height: "100%", width: "100%" }} />
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((u, i) => (
            <TableRow key={i}>
              <TableCell editMode={editMode} align="left">
                {u.firstName}
              </TableCell>
              <TableCell editMode={editMode} align="left">
                {u.lastName}
              </TableCell>
              <TableCell editMode={editMode} align="right">
                {editMode ? (
                  <IconButton onClick={() => deleteUser(i)} style={{ padding: 10 }}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <div style={{ height: "100%", width: "100%" }} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default UsersSelectorTable;
