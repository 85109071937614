import React, { Component } from "react";
import { withTheme } from "@material-ui/core";
import * as Swal from "sweetalert2";
import { handleResponse, get, del, swal500 } from "../../../utils/network";
import { TableComponent } from "../../common";
import COLUMNS from "./columns";

const MESSAGES = {
  created: "El tablero ha sido creado correctamente",
  edited: "El tablero ha sido editado correctamente",
  deleted: "El tablero ha sido eliminado correctamente",
};

class DashboardsTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      maxPageFetched: 0,
      editing: false,
      items: [],
      itemsCount: 0,
      loading: true,
      updating: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    this.getItems();
    const msgKey = this.props.location.state?.msg;
    if (msgKey) {
      const msg = MESSAGES[msgKey];
      msg && this.setState({ successMessage: msg });
    }
  }

  getItems = () => {
    get("dashboards?page=0")
      .then(res => handleResponse(res, this.props))
      .then(parsed =>
        this.setState({
          items: parsed.message.data,
          itemsCount: parsed.message.count,
          page: 0,
          maxPageFetched: 0,
          loading: false,
          updating: false,
        })
      )
      .catch(err => {
        this.setState({ loading: false, updating: false });
        swal500(err);
      });
  };

  showMore = page => {
    this.setState({ updating: true }, () => {
      get("dashboards?page=" + page)
        .then(res => handleResponse(res, this.props))
        .then(parsed =>
          this.setState(prevState => ({
            items: prevState.items.concat(parsed.message.data),
            maxPageFetched: page,
            loading: false,
            updating: false,
          }))
        )
        .catch(err => {
          this.setState({ loading: false, updating: false });
          swal500(err);
        });
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      window.scrollTo(0, 0);
      if (newPage > this.state.maxPageFetched) {
        this.showMore(newPage);
      }
    });
  };

  onCloseToast = toastKey => () => this.setState({ [toastKey]: "" });

  handleOpenForm = id => {
    console.log(id);
    id
      ? this.props.history.push("/home/dashboards/" + id)
      : this.props.history.push("/home/dashboards/new");
  };

  handleDelete = id => {
    const item = this.state.items.find(u => u._id === id);
    const { palette } = this.props.theme;
    Swal.fire({
      title: "Eliminar tablero",
      text: `¿Estas seguro de que queres eliminar el tablero ${item.name}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: palette.primary.main,
      confirmButtonColor: palette.state.failure,
      cancelButtonText: "No, conservar tablero",
      confirmButtonText: "Si, estoy seguro",
    }).then(result => {
      if (result.value) {
        this.setState({ updating: true }, () => {
          del(`dashboards/${item._id}`)
            .then(res => handleResponse(res, this.props))
            .then(result =>
              this.setState(prevState => ({
                items: prevState.items.filter(r => r._id !== id),
                itemsCount: prevState.itemsCount - 1,
                updating: false,
                successMessage: MESSAGES.deleted,
              }))
            )
            .then(res => this.getItems())
            .catch(err => {
              swal500(err);
              this.setState({ updating: false });
            });
        });
      }
    });
  };

  render() {
    return (
      <TableComponent
        data={this.state}
        path="/home/dashboards/"
        newMessage="Nuevo tablero"
        columns={COLUMNS}
        dataKey="items"
        handleOpenForm={this.handleOpenForm}
        handleDelete={this.handleDelete}
        handleChangePage={this.handleChangePage}
        onCloseToast={this.onCloseToast}
        successMessage={this.state.successMessage}
      />
    );
  }
}

export default withTheme(DashboardsTableContainer);
