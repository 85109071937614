import React from "react";
import {
    CircularProgress,
    Grid,
    Typography,
    Button,
    withStyles,
} from "@material-ui/core";
import { Person as PersonIcon, InfoOutlined } from '@material-ui/icons';
import styles from './styles';
import PasswordResetDialog from "../PasswordReset/PasswordResetDialog";
import { SuccessToast, CustomInput, CustomPassword } from "../../components/common";
import oauthOptions from "./oauthOptions";

const LoginComponent = ({
    classes,
    data,
    handleChange,
    handleSubmit,
    successMessage,
    passwordResetOpen,
    handlePasswordReset,
    passwordResetSuccess,
    onCloseToast,
    redirectOauth,
    setShowUserAndPassword
}) => (
    <div className={classes.background}>
        {
            data.token ? (
              <div className={classes.loadingToken}>
                  <CircularProgress size={60}/>
                  <p style={{ fontSize: 18 }}>Iniciando sesión...</p>
              </div>
            ) : (
              <>
                {
                    data.loading &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                }
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.gridLogin}
                >
                    <form className={classes.formLogin} onSubmit={handleSubmit}>
                        {/* <img
                            src="logo.png"
                            width={200}
                            height={130}
                            alt="Logo"
                            style={{ margin: '0 auto' }}
                        /> */}
                        <Typography
                            component="h1"
                            variant="h5"
                            className={classes.title}
                        >
                            Inicio de sesión
                        </Typography>
                        { data.errorOauth &&
                        <div className={classes.errorContainer}>
                            <InfoOutlined className={classes.errorIcon}/>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 10 }}>
                                <p style={{ margin: 0, textAlign: 'left' }}>
                                    Código: {data.errorUuid}
                                </p>
                                <p style={{ margin: 0, paddingTop: 5, textAlign: 'left' }}>
                                    Detalles: {data.errorOauth}
                                </p>
                            </div>
                        </div>
                        }
                        <div>
                            {oauthOptions.map(({ label, backgroundColor, icon, endpoint, labelColor="#FFF"}) => (
                              <Button
                                variant="contained"
                                // startIcon={icon}
                                className={classes.oauthButton}
                                style={{ backgroundColor , color: labelColor }}
                                fullWidth
                                onClick={() => redirectOauth(endpoint)}
                              >
                                  {label}
                              </Button>
                            ))}
                        </div>
                        {
                            !data.showUserAndPassword ? (
                                <div className={classes.showUserAndPassword}>
                                    <p onClick={() => setShowUserAndPassword(true)} className={classes.showUserAndPasswordLabel}>
                                        Iniciar sesión con usuario y contraseña
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className={classes.inputLogin}>
                                        <CustomInput
                                            error={data.errorusername}
                                            name="username"
                                            label="Usuario"
                                            type="text"
                                            handleChange={handleChange}
                                            value={data.username}
                                            icon={<PersonIcon className={classes.inputIcon} />}
                                        />
                                    </div>
                                    <div className={classes.inputLogin}>
                                        <CustomPassword
                                            error={data.errorpassword}
                                            name="password"
                                            placeholder="Contraseña"
                                            handleChange={handleChange}
                                            value={data.password}
                                            variant="outlined"
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.signInButton}
                                        onClick={handleSubmit}
                                    >
                                        Iniciar sesión
                                    </Button>
                                    <div className={classes.rememberContainer}>
                                        <p onClick={() => handlePasswordReset(true)} className={classes.forgetPassword}>
                                            Olvidé mi contraseña
                                        </p>
                                    </div>
                                </>
                            )
                        }
                    </form>
                </Grid>
                <PasswordResetDialog
                    open={passwordResetOpen}
                    onClose={() => handlePasswordReset(false)}
                    onSubmit={passwordResetSuccess}
                />
                <SuccessToast
                    open={successMessage !== ''}
                    onClose={onCloseToast('successMessage')}
                    text={successMessage}
                />
            </>
            )}
    </div>
);

export default withStyles(styles)(LoginComponent);