import React from "react";
import { Layout } from "../components/common";
import { Route, Redirect, Switch } from "react-router-dom";
import UserTableContainer from "../components/Users/UsersTable/UserTableContainer";
import UserFormContainer from "../components/Users/UsersForm/UserFormContainer";
import RolesTableContainer from "../components/Roles/RolesTable/RolesTableContainer";
import RolesFormContainer from "../components/Roles/RolesForm/RolesFormContainer";
import DashboardsTableContainer from "../components/Dashboards/DashboardsTable/DashboardsTableContainer";
import DashboardsFormContainer from "../components/Dashboards/DashboardsForm/DashboardsFormContainer";
import SessionsList from "../components/Sessions/SessionsList";
import ProfileFormContainer from "../components/Profile/ProfileFormContainer";
import { getCookie } from "../utils/utils";
import Dashboard from "../components/Dashboard/Dashboard";

export default function Home(props) {
  const isLogged = getCookie("isLogged");
  return !isLogged ? (
    <Redirect from="/home/*" to="/login" />
  ) : (
    <Layout history={props.history}>
      <Switch>
        <Route exact path="/home/users" component={UserTableContainer} />
        <Route exact path="/home/users/:id" component={UserFormContainer} />
        <Route exact path="/home/roles" component={RolesTableContainer} />
        <Route exact path="/home/roles/:id" component={RolesFormContainer} />
        <Route exact path="/home/sessions" component={SessionsList} />
        <Route exact path="/home/profile" component={ProfileFormContainer} />
        <Route exact path='/home/dashboards' component={DashboardsTableContainer} />
        <Route exact path='/home/dashboards/:id' component={DashboardsFormContainer} />
        <Route exact path="/home/view-dashboards/:id" component={Dashboard} />
        <Redirect from="/home" to={JSON.parse(getCookie("routeOptions"))[0].path} exact />
        <Redirect from="/home/*" to="/404" />
      </Switch>
    </Layout>
  );
}
