import { useCallback, useState } from "react";

function useFormValidation(initialState, validate) {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});

    const resetError = name => setErrors({ ...errors, [name]: undefined });

    const handleChange = event => {
        const { name, value } = event.target;
        resetError(name);
        setValues({
            ...values,
            [name]: value
        });
    };

    const resetValues = useCallback(values => {
        setValues(values);
    }, []);

    const handleBlur = event => {
        const { name } = event.target;
        const validationErrors = validate(values);
        setErrors({ ...errors, [name]: validationErrors[name] });
    };

    const handleSubmit = event => {
        event.preventDefault();
        const validationErrors = validate(values);
        setErrors(validationErrors);
        return Object.entries(validationErrors).length === 0;
    };

    return {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    };
}

export default useFormValidation;
