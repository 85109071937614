import React, { useEffect, useState } from "react";
import { handleResponse, swal500, get } from "../../utils/network";
import { Loading } from "../common";

const Dashboard = props => {
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardURL, setDashboardURL] = useState("");
  const [permissionDenied, setPermissionDenied] = useState(false);

  useEffect(() => {
    const getDashboard = () => {
      setLoading(true);
      setDashboardLoading(true);
      get(`dashboards/${props.match.params.id}/url`)
        .then(res => handleResponse(res, props))
        .then(parsed => {
          setDashboardURL(parsed.message);
          setLoading(false);
        })
        .catch(err => {
          if (err === 400 || err === 404) {
            props.history.push("/404");
            return;
          }
          if (err === 403) setPermissionDenied(true);
          setLoading(false);
        });
    };
    getDashboard();
  }, [props]);

  return loading ? (
    <Loading />
  ) : (
    !permissionDenied && (
      <div style={{ minHeight: "100vh", padding: 10 }}>
        {dashboardLoading && <Loading />}
        <iframe
          title="Dashboard"
          src={dashboardURL}
          frameBorder="0"
          height="100%"
          width="100%"
          onLoad={() => setDashboardLoading(false)}
          style={{ display: dashboardLoading ? "none" : undefined, height: "95vh" }}
        />
      </div>
    )
  );
};

export default Dashboard;
