import React, { Component } from 'react'
import Header from './Header'
import DrawerLeft from './DrawerLeft'
import { handleResponse, post } from '../../utils/network';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    childrenContainer: {
        minHeight: '100vh',
        margin: "20px 40px",
        [theme.breakpoints.down("md")]: {
            margin: 20
        },
        [theme.breakpoints.down("xs")]: {
            margin: "20px 0"
        }
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 3
    }
});

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            drawerOpen: window.innerWidth > 1120,
        }
    }

    handleDrawerToggleMobile = (value) => {
        if (value !== undefined) {
            this.setState({ mobileOpen: value });
        } else {
            this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
        }
    };

    handleDrawerToggle = (value) => {
        if (value !== undefined) {
            this.setState({ drawerOpen: value });
        } else {
            this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen }));
        }
    };

    logout = () => {
        post('auth/logout', "")
            .then(res => {
                return handleResponse(res, this.props);
            })
            .then(data => {
                this.props.history.push("/login");
            })
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ display: 'flex' }}>
                <DrawerLeft
                    history={this.props.history}
                    mobileOpen={this.state.mobileOpen}
                    drawerOpen={this.state.drawerOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    handleDrawerToggleMobile={this.handleDrawerToggleMobile}
                />
                <div className={classes.layout}>
                    <Header
                        title="Menú"
                        handleDrawerToggle={this.handleDrawerToggle}
                        logout={this.logout}
                        handleDrawerToggleMobile={this.handleDrawerToggleMobile}
                    />
                    <div className={classes.childrenContainer}>
                     {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(Layout);
