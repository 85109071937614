import React, { useState, useEffect } from "react";
import { handleResponse, post, patch, swal500, del, get } from "../../../utils/network";
import { useFormValidation } from "../../common";
import validate from "./validator";
import DashboardsFormComponent from "./DashboardsFormComponent";
import * as Swal from "sweetalert2";
import { useMediaQuery, useTheme } from "@material-ui/core";

export const FIELDS = {
  name: "Nombre",
  metabaseId: "ID de tablero",
  jurisdictionFilter: "Filtrar por jurisdicción",
  allowedUsers: "Usuarios permitidos",
};

const INITIAL_STATE = {
  name: "",
  metabaseId: "",
  jurisdictionFilter: false,
  allowedUsers: [],
};

const DashboardsFormContainer = props => {
  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(props.location.editMode || false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isNew = Object.entries(data).length === 0;

  const { handleSubmit, handleChange, handleBlur, values, errors, setErrors, resetValues } =
    useFormValidation(INITIAL_STATE, values => validate(values, isNew));

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(values).forEach(key => {
      data[key] !== values[key] && changedValues.push(key);
    });
    return changedValues.filter(v => v !== "created");
  };

  const handle400 = err => {
    err.text().then(error => {
      let errorObject = JSON.parse(error);
      let errors = {};
      errorObject.fields.forEach(field => {
        const regex = new RegExp(field.name);
        errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
      });
      setErrors(errors);
    });
    return Promise.reject(400);
  };

  useEffect(() => {
    const getItem = () => {
      setLoading(true);
      get("dashboards/" + props.match.params.id)
        .then(res => handleResponse(res, props))
        .then(parsed => {
          if (parsed.error) props.history.push("/home/dashboards");
          else {
            setData(parsed.message.data);
            resetValues(parsed.message.data);
            setLoading(false);
          }
        });
    };

    if (props.match.params.id === "new") {
      setEditMode(true);
      setLoading(false);
    } else {
      getItem();
    }
  }, [props, resetValues]);

  const handleClose = () => props.history.push("/home/dashboards");

  const handleDelete = () => {
    Swal.fire({
      title: "Eliminar tablero",
      text: `¿Estas seguro de que queres eliminar el tablero ${values.name}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#4eaaf3", //TODO: theme.palette.primary.main
      confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
      cancelButtonText: "No, conservar tablero",
      confirmButtonText: "Si, estoy seguro",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Swal.update({ showCancelButton: false });
        return del(`dashboards/${props.match.params.id}`)
          .then(res => handleResponse(res, props))
          .then(result =>
            props.history.push({
              pathname: "/home/dashboards",
              state: { msg: "deleted" },
            })
          )
          .catch(err => {
            swal500(err);
          });
      },
    });
  };

  const handleResult = e => {
    const formValid = handleSubmit(e);
    if (formValid) {
      setSubmitting(true);
      const { allowedUsers, jurisdictionFilter, metabaseId, name } = values;
      if (isNew) {
        const body = {
          name,
          metabaseId: parseInt(metabaseId),
          jurisdictionFilter,
          allowedUsers: allowedUsers.map(i => i._id),
        };
        post("dashboards", body)
          .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
          .then(res => {
            setSubmitting(false);
            props.history.push({
              pathname: "/home/dashboards",
              state: { msg: "created" },
            });
          })
          .catch(err => {
            setSubmitting(false);
            if (err !== 400) {
              swal500(err);
            }
          });
      } else {
        let body = {};
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          props.history.push("/home/dashboards");
          return;
        }
        changedValues.forEach(value => {
          if (value === "allowedUsers")
            body = { ...body, allowedUsers: allowedUsers.map(i => i._id) };
          else if (value === "metabaseId") body = { ...body, metabaseId: parseInt(metabaseId) };
          else body = { ...body, [value]: values[value] };
        });
        patch(`dashboards/${data._id}`, body)
          .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
          .then(res => {
            setSubmitting(false);
            props.history.push({
              pathname: "/home/dashboards",
              state: { msg: "edited" },
            });
          })
          .catch(err => {
            setSubmitting(false);
            if (err !== 400) {
              swal500(err);
            }
          });
      }
    }
  };

  return (
    <DashboardsFormComponent
      errors={errors}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleClose={handleClose}
      handleResult={handleResult}
      handleDelete={handleDelete}
      loading={loading}
      submitting={submitting}
      values={values}
      editMode={editMode}
      setEditMode={setEditMode}
      isNew={isNew}
      smallScreen={smallScreen}
    />
  );
};

export default DashboardsFormContainer;
