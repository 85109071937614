import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";

const MESSAGES = {
    created: 'El usuario ha sido creado correctamente',
    edited: 'El usuario ha sido editado correctamente',
    deleted: 'El usuario ha sido eliminado correctamente'
};

class UserTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            users: [],
            usersCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    componentDidMount() {
        this.getUsers();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    getUsers = () => {
        get('users?page=0')
            .then(res => handleResponse(res, this.props))
            .then(parsed =>
                this.setState({
                    users: parsed.message.data,
                    usersCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get('users?page=' + page)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            users: prevState.users.concat(parsed.message.data),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = id => id ? this.props.history.push('/home/users/' + id) : this.props.history.push('/home/users/new');

    handleDelete = userId => {
        const user = this.state.users.find(u => u._id === userId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar usuario",
            text: `¿Estas seguro de que queres eliminar al usuario ${user.firstName} ${user.lastName}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar usuario',
            confirmButtonText: 'Si, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`users/${user._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        users: prevState.users.filter(r => r._id !== userId),
                                        usersCount: prevState.usersCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getUsers())
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    render() {
        return (
            <TableComponent
                data={this.state}
                path="/home/users/"
                newMessage="Nuevo usuario"
                columns={COLUMNS}
                dataKey="users"
                handleOpenForm={this.handleOpenForm}
                handleDelete={this.handleDelete}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
            />
        )
    }
}

export default withTheme(UserTableContainer);
