export const AllPermissions = {
  all: "5ee8d23c798dc64752c2cf01",
  manageUsers: "5ee91401fd71adae46a45bfc",
  viewUsers: "5ee8d282798dc64752c2cf02",
  manageRoles: "5ee915b8fd71adae46a45bfd",
  viewRoles: "5ee91c3afd71adae46a45bfe",
  viewPermissions: "5eea67579873c743a0a7f5da",
  manageOthersSessions: "5efb9e9af62305f8d0153890",
  viewDashboardMapa: "5f2310ed5aa4ccce143ade4b",
  viewDashboardLogs: "5f2310f35aa4ccce143ade4c",
  viewDashboardCursos: "5f2da6d69f506d29a10f1ee0",
  viewDashboardSistema: "5f36f9b8f8da761acee3386e",
  viewDashboardAulaVirtual: "5f36f9b8f8da761acee3389f",
  viewDashboardEquipamiento: "5f36f9b8f8da761acee33900",
  viewDashboardConectividad: "5f36f9b8f8da761acee33901",
  manageDashboards: "60d649051f0b89a405ce9916",
};
