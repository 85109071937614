import React from "react";
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Typography,
    withStyles,
    TextField,
    Card,
    Avatar,
} from "@material-ui/core";
import { Create as CreateIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./ProfileFormContainer";
import { Loading, SuccessToast, ErrorToast } from "../../components/common"

const ProfileFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleResult,
        handleUploadImage,
        submitting,
        submittingImage,
        loading,
        values,
        editMode,
        setEditMode,
        imageUrl,
        successMessage,
        errorMessage,
        onCloseToast,
    } = props;

    const renderActions = () => (//not used because its readonly
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
        </div>
    );

    const ProfileCard = () => (
        <Card elevation={0} className={classes.profileCard}>
            <Avatar
                alt="Remy Sharp"
                src={imageUrl}
                className={classes.image}
            />
            {editMode &&
                <div className={classes.profileCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleUploadImage}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.profileCardLabel} htmlFor="upload-file">
                        {submittingImage &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submittingImage}
                            className={classes.profileCardButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </Card>
    );

    return (
        <div>
            <Paper elevation={2} className={classes.root}>
                <div className={classes.formHeader}>
                    <div className={classes.titleContainer} >
                        <Typography style={{ fontSize: 20 }}>
                            Mi Perfil
                    </Typography>
                    </div>
                </div>
                {loading ? (
                    <Loading />
                ) : (
                        <div className={classes.contentContainer}>
                            <ProfileCard />
                            <form className={classes.form}>
                                <TextField
                                    name='firstName'
                                    error={errors.firstName !== undefined}
                                    helperText={errors.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName || "-"}
                                    label={FIELDS['firstName']}
                                    className={classes.textField}
                                    InputProps={{
                                        readOnly: !editMode,
                                        disabled: !editMode,
                                        disableUnderline: !editMode
                                    }}
                                />
                                {editMode && <div className={classes.divider} />}
                                <TextField
                                    name='lastName'
                                    error={errors.lastName !== undefined}
                                    helperText={errors.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName || "-"}
                                    label={FIELDS['lastName']}
                                    className={classes.textField}
                                    InputProps={{
                                        readOnly: !editMode,
                                        disabled: !editMode,
                                        disableUnderline: !editMode
                                    }}
                                />
                                {editMode && <div className={classes.divider} />}
                                <TextField
                                    name='cuil'
                                    error={errors.cuil !== undefined}
                                    helperText={errors.cuil}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cuil || "-"}
                                    label={FIELDS['cuil']}
                                    className={classes.textField}
                                    InputProps={{
                                        readOnly: !editMode,
                                        disabled: !editMode,
                                        disableUnderline: !editMode
                                    }}
                                />
                                <div className={classes.actionsContainer}>
                                    {
                                        editMode &&
                                        <>
                                            {
                                                submitting &&
                                                <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                                            }
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={loading || submitting}
                                                onClick={handleResult}
                                            >
                                                Confirmar
                                    </Button>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    )
                }
            </Paper >
            <SuccessToast
                open={successMessage !== ''}
                onClose={onCloseToast('successMessage')}
                text={successMessage}
            />
            <ErrorToast
                open={errorMessage !== ''}
                onClose={onCloseToast('errorMessage')}
                text={errorMessage}
            />
        </div>
    );
};

export default withStyles(styles)(ProfileFormComponent);