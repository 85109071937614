export const CUIL_REGEX = /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default (values) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Complete el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe ser un nombre válido"
    }


    if (!values.lastName) {
        errors.lastName = "Complete el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe ser un apellido válido"
    }

    if (!values.cuil) {
        errors.cuil = "Complete el CUIL";
    } else if (!CUIL_REGEX.test(values.cuil)) {
        errors.cuil = "El CUIL debe ser válido";
    }

    return errors;
}